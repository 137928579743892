import React, { useMemo } from "react";
import { Select } from "clutch/src/Select/Select.jsx";

import { WEAPON_CATEGORIES } from "@/game-cs2-marketing/constants.mjs";

const FilterWeaponCategory = ({ onChange, selected }) => {
  const options = useMemo(
    () =>
      WEAPON_CATEGORIES.map(({ value, i18nKey, text }) => ({
        value,
        text: [i18nKey, text] satisfies Translation,
      })),
    [],
  );

  return <Select onChange={onChange} selected={selected} options={options} />;
};

export default FilterWeaponCategory;
