import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import { updateRoute } from "@/__main__/router.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { WEAPONS } from "@/game-cs2-marketing/constants.mjs";
import FilterWeaponCategory from "@/game-cs2-marketing/FilterWeaponCategory.jsx";
import { getWeaponIcon } from "@/game-cs2-marketing/utils.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import ContentContainer from "@/shared/ContentContainer.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import Tilting from "@/shared/Tilting.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

export const WeaponsGrid = styled("div")`
  display: grid;
  gap: var(--sp-4);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;

export const WeaponsGridItem = styled("a")`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  aspect-ratio: 240 / 124;
  background: var(--shade7);
  border-radius: var(--br-lg);
  padding: var(--sp-4);

  img {
    width: 100%;
    height: auto;
  }
  .weapon-name {
    display: block;
    color: var(--shade0);
  }
  &:hover:before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: var(--sp-0_5) solid var(--shade4);
    border-radius: var(--br-xl);
  }
`;

const WeaponImage = styled<{ $bgUrl: string }>("div")`
  background-image: url(${({ $bgUrl }) => $bgUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: var(--sp-16);
`;

const StyledFilterBar = styled(FilterBar)`
  margin-bottom: var(--sp-4);
`;

const Filters = ({ category, setCategory, searchText, setSearch }) => {
  const { t } = useTranslation();

  return (
    <StyledFilterBar className={undefined} hiddenItems={undefined}>
      <TextInput
        defaultValue={searchText}
        placeholder={t("csgo:search.weapons", "Search Weapons")}
        onValueChange={setSearch}
        Icon={SearchIcon}
      />
      <FilterWeaponCategory
        onChange={(c) => setCategory(c)}
        selected={category}
      />
    </StyledFilterBar>
  );
};

function DatabaseWeapons() {
  const { t } = useTranslation();
  const { currentPath, searchParams } = useRoute();
  const category = searchParams.get("category") || "all";
  const setCategory = useCallback(
    (category: string) => {
      searchParams.set("category", category);
      updateRoute(currentPath, searchParams);
    },
    [currentPath, searchParams],
  );
  const [searchText, setSearch] = useState("");
  const weapons = useMemo(() => {
    return Object.values(WEAPONS).filter((w) => {
      if (category !== "all" && w.category !== category) return false;
      return w.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [category, searchText]);
  return (
    <PageContainer>
      <PageHeader title={t("common:navigation.cs2Guns", "CS2 Guns")} />
      <ContentContainer>
        <Filters
          category={category}
          setCategory={setCategory}
          searchText={searchText}
          setSearch={setSearch}
        />
        <WeaponsGrid>
          {weapons.map((w) => {
            return (
              <Tilting key={w.key}>
                <WeaponsGridItem
                  href={`/${GAME_SHORT_NAMES[GAME_SYMBOL_CS2]}/database/weapons/${w.key}/overview`}
                >
                  <WeaponImage $bgUrl={getWeaponIcon(w.icon, null)} />
                  <span className="weapon-name type-subtitle2">{w.name}</span>
                </WeaponsGridItem>
              </Tilting>
            );
          })}
        </WeaponsGrid>
      </ContentContainer>
    </PageContainer>
  );
}

export function meta() {
  return {
    title: [
      "csgo:meta.weapons.title",
      "Weapon Stats - Avg. Headshot %, Kills/Match, and More",
    ],
    description: [
      "csgo:meta.weapons.description",
      "Dive into the definitive guide of Counter-Strike 2's arsenal with our Weapons Database, featuring in-depth analyses, stats, and strategies for every weapon in the game.",
    ],
    subtitle: [
      "csgo:meta.weapons.subtitle",
      "Check out weapon headshot % by range, primary and alt fire modes and rates of fire, magazine capacity, wall penetration rating",
    ],
  };
}
export default DatabaseWeapons;
